// import { useCallback, useEffect, useMemo, useState } from 'react';
// import { useForm } from 'react-hook-form';
// // @mui
// import LoadingButton from '@mui/lab/LoadingButton';
// import Alert from '@mui/material/Alert';
// import Link from '@mui/material/Link';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// // hooks
// // routes
// import { RouterLink } from 'src/routes/components';
// import { useParams, useSearchParams } from 'src/routes/hook';
// import { paths } from 'src/routes/paths';
// // config
// // auth
// import { useAuthContext } from 'src/auth/hooks';
// // components
// import { useLocales } from 'src/locales';
// import { clientResetPasswordSendLink } from 'src/redux/slices/auth';
// import DynamicForByPageId from 'src/sections/masters/settings/dynamic-form/render-blocks/dynamic-form-by-page-id';
// import { CONSTANTDEFAULTPAGES } from 'src/utils/contants/config-utils';
// import { newFormGetFormBlockSetupTableId } from 'src/utils/dynamic.form.generation.common.code';

// // ----------------------------------------------------------------------

// type FormValuesProps = {
//   email: string;
//   password: string;
//   firstName: string;
//   lastName: string;
// };

// export default function JwtClientForgotPasswordView() {
//   const { register } = useAuthContext();
//   const { t } = useLocales();
//   const [errorMsg, setErrorMsg] = useState(false);
//   const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
//   const [pageId, setPageId] = useState(CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD);
//   const [selectedRow, setSelectedRow] = useState({} as any);
//   const [watchFields, setWatchFields] = useState([] as any);
//   const [loading, setLoading] = useState(false);

//   const searchParams = useSearchParams();
//   const param = useParams();
//   const clienName = param.name;
//   const returnTo = searchParams.get('returnTo');
//   useEffect(() => {
//     newFormGetFormBlockSetupTableId(0, pageId, watchFields, setWatchFields, setSelectedRow,setLoading);
//   }, [])

//   const defaultValues = useMemo(() => ({
//     ...selectedRow
//   }), [selectedRow]);
  
//   const methods = useForm<FormValuesProps>({
//   });

 
//   const {
//     handleSubmit, setValue, getValues,watch, control,
//     formState: { isSubmitting }
// } = methods;

//   const onSubmitHandler = useCallback(
//     (data: any) => {
//       clientResetPasswordSendLink(CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD,data.EmailAddress).then((res:any)=>{
//         if (res.data.data > 0) {
//           setForgotPasswordStatus(true);
//         } else {
//           setErrorMsg(true)
//         }
//       });
//     }, []);

//   const renderHead = (
//     <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
//       <Typography variant="h4">{t("FORGOTPASSWORDFORMTITLE")}</Typography>
//       <Stack direction="row" spacing={0.5}>
//         <Typography variant="body2"> {t('AREADY_HAVE_ACCOUNT')} </Typography>
//         <Link href={`${paths.auth.jwt.login}${clienName}`} component={RouterLink} variant="subtitle2">
//         {t('SIGNIN')}
//         </Link>
//       </Stack>
//     </Stack>
//   );

//   const renderTerms = (
//     <Typography
//       component="div"
//       sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
//     >
//       {t('SIGNUP_IAGREE')}
//       <Link underline="always" color="text.primary">
//         {t('TERMS_SERVICE')}
//       </Link>
//        &nbsp;{t('AND')} &nbsp;
//       <Link underline="always" color="text.primary">
//         {t('PRIVACY_POLICY')}
//       </Link>
//       .
//     </Typography>
//   );

//   const renderForm = (
//     <Stack spacing={2.5}>
//       {!!errorMsg && <Alert severity="error">{t("FORGOTPASSWORD_FAILED")}</Alert>}
//         {!forgotPasswordStatus && selectedRow && selectedRow?.formBlockList &&  <DynamicForByPageId key={crypto.randomUUID()} pageId={pageId} 
//          currentModel={defaultValues}   onSubmitHandler={onSubmitHandler}  children={<LoadingButton fullWidth  color="inherit" size="large" type="submit" variant="contained" loading={isSubmitting}>
//          {t("REQUEST_PASSWORD")}
//         </LoadingButton>} />}

//      {forgotPasswordStatus &&   <>
//        <Alert severity="success">{t("FORGOTPASSWORD_SUCCESS")}</Alert>
//        <Alert severity="success"><div dangerouslySetInnerHTML= {{__html: t("FORGOTPASSWORD_SUCCESS_CONTENT") as any}}/></Alert>
//       </> }
//     </Stack>
//   );

//   return (
//     <>
//       {renderHead}
//       {/* <Typography variant="h4" style={{fontSize:'15px'}}>{t("FORGOTPASSWORD")}</Typography> */}
//       {renderForm}
//       {/* {renderTerms} */}
//     </>
//   );
// }
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { RouterLink } from 'src/routes/components';
import { useParams, useSearchParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// config
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import DynamicFormNewPageById from 'src/sections/masters/settings/dynamic-form/dynamic-form-new-page-by-id';
import { CONSTANTDEFAULTPAGES } from 'src/utils/contants/config-utils';
import { useLocales } from 'src/locales';
import { newFormGetFormBlockSetupTableId } from 'src/utils/dynamic.form.generation.common.code';
import DynamicForByPageId from 'src/sections/masters/settings/dynamic-form/render-blocks/dynamic-form-by-page-id';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { resetPasswordSendLink } from 'src/redux/slices/auth';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
// import { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function JwtClientForgotPasswordView() {
  const { t } = useLocales();
  const [errorMsg, setErrorMsg] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [pageId, setPageId] = useState(CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD);
  const [selectedRow, setSelectedRow] = useState({} as any);
  const [gridData, setGridData] = useState([] as any);
  const [watchFields, setWatchFields] = useState([] as any);
  const [loading, setLoading] = useState(true as any);
  const param = useParams();

  const validateModelSchema = Yup.object().shape({
    EmailAddress: Yup.string().required(t("Email address is required") as any),
    id: Yup.number(),
  });

  const defaultValues = useMemo(() => ({
    EmailAddress: "",
    id: 0
  }), []);

  const methods = useForm<any>({
    resolver: yupResolver(validateModelSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit, setValue, watch,
    formState: { isSubmitting },
  } = methods;
  const values = watch();


  useEffect(() => {
    newFormGetFormBlockSetupTableId(0, pageId, watchFields, setWatchFields, setSelectedRow, setLoading);
    setLoading(true)
  }, [])

  const onSubmitHandler = useCallback(
    (data: any) => {
      const resquestObj = {client:param.name,studyId:'',emailAddress:data.EmailAddress?.trim(),pageId:CONSTANTDEFAULTPAGES.CLINETFORGOTPASSWORD}
      resetPasswordSendLink(resquestObj).then((res: any) => {
        if (res.data.data > 0) {
          setForgotPasswordStatus(true);
        } else {
         setErrorMsg(true)
        }
      });
    }, []);



  const renderHead = (
    <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
      <Typography variant="h4">{t("FORGOTPASSWORDFORMTITLE")}</Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {t('AREADY_HAVE_ACCOUNT')} </Typography>
        <Link href={`/client/${param.name}`} component={RouterLink} variant="subtitle2">
          {t('SIGNIN')}
        </Link>
      </Stack>

    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {t('SIGNUP_IAGREE')}
      <Link underline="always" color="text.primary">
        {t('TERMS_SERVICE')}
      </Link>
      &nbsp;{t('AND')} &nbsp;
      <Link underline="always" color="text.primary">
        {t('PRIVACY_POLICY')}
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{t("FORGOTPASSWORD_FAILED")}</Alert>}
      {!forgotPasswordStatus && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandler)}>
          <Box rowGap={1} columnGap={1} sx={{ p: 1 }} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
            <Stack spacing={1} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
              <RHFTextField name="EmailAddress" label="Email Address" />
            </Stack>
          </Box>
          {/* <Button variant="outlined" >
            {t("CANCEL")}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            fORGOT PASSWORD
          </LoadingButton> */}
          <LoadingButton fullWidth  color="inherit" size="large" type="submit" variant="contained" loading={isSubmitting}>
         {t("REQUEST_PASSWORD")}
        </LoadingButton>
      </FormProvider> }
      {forgotPasswordStatus &&   <>
       <Alert severity="success">{t("FORGOTPASSWORD_SUCCESS")}</Alert>
       <Alert severity="success"><div dangerouslySetInnerHTML= {{__html: t("FORGOTPASSWORD_SUCCESS_CONTENT") as any}}/></Alert>
      </> }

    </Stack>
  );

  return (
    <>
      {renderHead}
      {/* <Typography variant="h4" style={{ fontSize: '15px' }}>{t("FORGOTPASSWORD")}</Typography> */}
      {renderForm}
      {/* {renderTerms} */}
    </>
  );
}
