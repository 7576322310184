import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useParams } from 'react-router';
import { useCallback } from 'react';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  controlObj:any;
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any;
}

export default function DAS360Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({ currentData,conditionFlags,controlObj,
  name,
  label,
  placeholder,
  helperText,
  onChangeHandler,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue,register } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }

    const setLogData = useCallback((field:any,event:any)=>{
      if(event.target.value!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
        oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
      
        if(onChangeHandler) {
          const onchngeDaa ={oldValue,newValue:event.target.value}
          onChangeHandler(onchngeDaa);
        }
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue,newValue:event.target.value})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <> {!controlObj.showOnlyInGrid &&  <Autocomplete   disabled={controlObj.isReadonly || !conditionFlags?.enable}
          {...field}  {...register }
          onChange={(event, newValue) => { 
            setValue(name, newValue, { shouldValidate: true });
            setLogData(field,newValue);
           
            }}
          renderInput={(params) => (
            <TextField
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />}</>
      )}
    />
  );
}
