import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Theme, SxProps,alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useParams } from 'react-router';
import { useCallback, useEffect } from 'react';

// ----------------------------------------------------------------------

type RHFSelectProps = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  PaperPropsSx?: SxProps<Theme>;
  controlObj:any;
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any;
};

export function DAS360Select({onChangeHandler, currentData,conditionFlags,controlObj, 
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  PaperPropsSx,
  ...other
}: RHFSelectProps) {
  const { control,setError,register,setValue,getValues  } = useFormContext();
  const param = useParams();
  const viewId = currentData?.pageId || param?.id || 0;
  let optionData: any = !controlObj.dependencyDropdownKeyCol && !controlObj.parentColumnName ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
    optionData = typeof optionData === 'string' ? [] : optionData
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }
    const setLogData = useCallback((field:any,event:any)=>{
      const filterData = optionData?.filter((x: any) => x.Id === currentData[field.name])[0]
      if(filterData)  {
      if(event !== filterData[controlObj.dropdownLabelName]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      
        if(onChangeHandler) {
          const onchngeDaa ={oldValue:filterData[controlObj.dropdownLabelName],newValue:event}
          onChangeHandler(onchngeDaa);
        }
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue:filterData[controlObj.dropdownLabelName],newValue:event})
      } else if(currentData.Id>0) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    } else { 
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue:"",newValue:event})
    }

    },[])
    // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
    const bgBorderColor:any = {};
  return (
    <Controller key={`${crypto.randomUUID()}`}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <> {!controlObj.showOnlyInGrid &&  <TextField key={`${crypto.randomUUID()}`} sx={{...bgBorderColor}} {...register } disabled={controlObj.isReadonly || !conditionFlags?.enable}
          {...field}
          select
          fullWidth
          onChange={(e) => {
            const filterData = optionData?.filter((x: any) => x.Id === e.target.value)[0]
            setValue(`${field.name}1`,filterData? filterData[controlObj.dropdownLabelName]:"");
            setValue(`${field.name}`, e.target.value);
            if (controlObj.childReference) {
                controlObj.childReference.split(',').forEach((item: any) => {
                    const dataD = (controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)).filter((x: any) => x.Id === e.target.value)[0][item];
                    setValue(controlObj.refDependencyDropdownKeyCol, dataD)
                })
            }
            setLogData(field,filterData? filterData[controlObj.dropdownLabelName]:"")
        }}
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                  }),
                  ...PaperPropsSx,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>}</>
      )}
    />
  );
}

// ----------------------------------------------------------------------

type RHFMultiSelectProps = SelectProps & {
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  options: {
    label: string;
    value: string;
  }[];
};

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}: RHFMultiSelectProps) {
  const { control } = useFormContext();

  const renderValues = (selectedIds: string[]) => {
    const selectedItems = options.filter((item) => selectedIds.includes(item.value));

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={<OutlinedInput fullWidth label={label} error={!!error} />}
            renderValue={renderValues}
            {...other}
          >
            {placeholder && (
              <MenuItem disabled value="">
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem key={option.value} value={option.value}>
                  {checkbox && <Checkbox size="small" disableRipple checked={selected} />}

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
