import { alpha } from '@mui/material/styles';
import { useCallback, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
import { useParams } from 'react-router';
//
import Editor, { EditorProps } from '../editor';

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string;
  controlObj:any
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any;
}

export default function DAS360Editor({currentData,conditionFlags,controlObj, name, helperText,onChangeHandler, ...other }: Props) {
  const {  control,setError,register,formState: { isSubmitSuccessful }} = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }
  
    const setLogData = useCallback((field:any,event:any)=>{
      if(event!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        if(onChangeHandler) {
          const onchngeDaa ={oldValue:currentData[field.name],newValue:event}
          onChangeHandler(onchngeDaa,controlObj);
        }
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue:currentData[field.name],newValue:event})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])


  // useEffect(() => {
  //     setValue(name, '', {
  //       shouldValidate: !isSubmitSuccessful,
  //     });
  // }, [isSubmitSuccessful, name, setValue]);

  return (
    <Controller {...register } 
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <> {!controlObj.showOnlyInGrid && <Editor  disabled={controlObj.isReadonly || !conditionFlags?.enable}
          id={name}
          value={field?.value?field?.value:""}
          onChange={(e)=>{
            setLogData(field,e);
            field.onChange(e)
          } }
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 2 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          {...other}
        />}</>
      )}
    />
  );
}
