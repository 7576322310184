import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  CONFIGURATION:{
    CONFIGURATIONDATA: '/api/Auth/GetConfigurationData',
  },
  auth: {
    me: '/api/Auth/isValidToken',
    login: '/api/Auth/DoLogin',
    register: '/api/auth/register',
    adminByClient: '/api/Auth/AdminByClient',
    AdminByClientById: '/api/Auth/AdminByClientById',
    AdminByStudyById: '/api/Auth/AdminByStudyById',
    refreshToken: '/api/Auth/refreshToken',
    forgotPassword: '/api/Auth/ResetPasswordSendLink',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  LOOKUP: {
    LIST: '/api/Lookup/GetLookupByKey/',
    GETLOOKUPBYKEY: '/api/Lookup//GetLookupByKey/',
    GETLOOKUPDATABYID: '/api/Lookup/GetLookupDataById/',
  },
 
  CLIENT: {
    me: '/api/Client/isValidToken',
    login: '/api/Client/DoLogin',
    register: '/api/Client/register',  
    forgotPassword: '/api/Client/ResetPasswordSendLink',
  },
  STUDY: {
    me: '/api/Study/isValidToken',
    login: '/api/Study/DoLogin',
    register: '/api/Study/register',  
    forgotPassword: '/api/Study/ResetPasswordSendLink',
  },
  COUNTRY: {
    LIST: '/api/lookup/',
    DELETE: '/api/vehicleClass/delete/',
    SEARCH: '/api/vehicleClass/search',
    ALL: '/api/vehicleClass/search',
  },
  VEHICLEMODEL: {
    LIST: '/api/VehicleModel/GetVehicleModels',
    ADDUPDATEVEHICLEMODEL: '/api/VehicleModel/AddUpdateVehicleModel',
    DELETE: '/api/VehicleModel/DeleteVehicleModel'
  },
  ACTIVITYLOG:{
    GETACTIVITYLOGBYPAGEID:'/api/ActivityLogFieldLevel/GetActivityLogByPageId',
    GETACTIVITYLOG:'/api/ActivityLogFieldLevel/GetActivityLog',
  },
  SETTINGS:{
    ROLES:{
      GETROLES:'/api/Role/GetRoles',
      GETVIEWROLES:'/api/Role/GetViewRoles',
    },
  
    STUDYTEMPLATE:{
      UPLOADBULKEXCEL:'/api/Upload/UploadBulkExcel',
      VERIFYSTUDY:'/api/Client/VerifyAssignStudy',
      UPDATESTUDYAPPROVALSTATUS:'/api/Client/UpdateStudyApprovalStatus',
      GETNOTESBYID:'/api/FormBlockSetup/GetNotesByFieldId',
      GETSUBMITQUERYBYID:'/api/FormBlockSetup/GetSubmitQueryByFieldId',
      GETATTACHMENTBYID:'/api/FormBlockSetup/GetAttachmentByFieldId',
      VERIFYSITEENROLMENT:'/api/Study/VerifySiteEnrollmentLimit',
    },
    TABLECONFIGURATION:{
      INSERTUPDATETABLECONFIGURATION: '/api/TableConfiguration/InsertUpdateTableColumn',
      INSERTUPDATETABLE: '/api/TableConfiguration/InsertUpdateTable',
      GETTABLECOLUMNS: '/api/TableConfiguration/GetTableColumns',
      GETTABLEINFO: '/api/TableConfiguration/GetTableInfo',
      DELETE: '/api/TableConfiguration/DeleteTable',
      DELETETABLECOLUMN: '/api/TableConfiguration/DeleteTableColumn',
      LOCKUNLOCK: '/api/TableConfiguration/LockUnlock',
    },
    PAGESETUP:{
      GETPAGES: '/api/PageSetup/GetPages',
      INSERTUPDATEPAGESETUP: '/api/PageSetup/InsertUpdatePageSetup',
      DELETE: '/api/PageSetup/DeletePage',
    },
    FORMDETAILS: {
      LIST: '/api/FormDetail/GetFormDetail',
      GETFORMDETAILSBYTABLEID: '/api/FormDetail/GetFormDetailByTableId',
      GETFORMDETAILSBYPAGEID: '/api/FormDetail/GetFormDetailByPageId',
      GETDETAILBYID: '/api/FormDetail/GetDetailById',
      GETSUBJECTENROLLMENTBYID: '/api/Study/GetSubjectEnrollmentById',
      GETVIEWDATABYID: '/api/FormDetail/GetViewDataById',
      GEFORMTVIEWDATABYID: '/api/FormDetail/GetFormViewDataById',
      GETVIEWDATABYTID: '/api/FormDetail/GetViewDataByTId',
      GETTABLEINFO: '/api/FormDetail/GetTableInfo',
      ADDUPDATEFORMDETAIL: '/api/FormDetail/InsertUpdateFormDetail',
      DELETE: '/api/FormDetail/DeleteVehicleHistory'
    },
    AUTH:{
      MENULIST: '/api/Auth/GetMenuList',
      GETFORMS: '/api/FormSetup/GetForms',
    },
    CLIENT: {
      ADDUPDATECLIENT: '/api/Client/InsertUpdateClient',
      DELETECLIENT: '/api/Client/DeleteClient',
    },
    DYNAMICFORM: {
      ADDUPDATEDYNAMICFORM: '/api/DynamicForm/InsertUpdateFormDetail',
      DELETEFORMDATADETAIL: '/api/DynamicForm/DeleteFormDataDetail',
    },
    FORMSETUP: {
      LIST: '/api/FormSetup/GetFormSetup',
      ADDUPDATEFORMSETUP: '/api/FormSetup/InsertUpdateFormSetup',
      DELETE: '/api/FormSetup/DeleteFormSetup'
    },

    FORMBLOCKSETUP: {
      LIST: '/api/FormBlockSetup/GetFormBlockSetup',
      GETFORMBLOCKSETUPBYTABLEID: '/api/FormBlockSetup/GetFormBlockSetupTableId',
      GETFORMDETAILBYFORMBLOCKBYPAGEID: '/api/FormBlockSetup/GetFormDetailbyFormBlockByPageId',
      GETFORMBLOCKCONTROLIDS: '/api/FormBlockSetup/GetFormBlockControlIds',
      ADDUPDATEFORMBLOCKSETUP: '/api/FormBlockSetup/InsertUpdateFormBlockSetup',
      GENERATESCRIPT: '/api/FormBlockSetup/GenerateScript',
      ADDUPDATEFORMDETAIL: '/api/FormBlockSetup/InsertUpdateForm',
      DELETE: '/api/FormBlockSetup/DeleteFormBlockSetup'
    },
  },
};
