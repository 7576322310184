import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useReducer, useState } from 'react';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths, ROOTS } from 'src/routes/paths';
import { useParams, useSearchParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { initialState, reducer, SYSTEM_ADMIN, Types } from 'src/auth/context/jwt/auth-provider';
import { setSession } from 'src/auth/context/jwt/utils';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
};

export default function JwtClientLoginView() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useLocales();
  const [errorMsg, setErrorMsg] = useState('');
  const param = useParams();
  const clienName = param.name;
 
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        sessionStorage.clear();
        sessionStorage.removeItem(SYSTEM_ADMIN);
        axios.post(API_ENDPOINTS.CLIENT.login, { emailAddress: data.email, password: data.password,clientName: clienName }).then((res) => {
          const { accessToken, user } = res.data.data;
          sessionStorage.clear();
          sessionStorage.setItem(SYSTEM_ADMIN, "client");
          setSession(accessToken);

          dispatch({
            type: Types.LOGIN,
            payload: {
              user,
            },
          });
          window.location.href = `/client/${clienName?.toLocaleLowerCase()}`;
        }).catch((error)=> {
          console.error(error);
          reset();
          setErrorMsg(t("LOGINFAILED") as any);
        });;
      } catch (error) {
        console.error(error);
        reset();
        setErrorMsg(t("LOGINFAILED") as any);
      }
    },
    [reset, returnTo]
  );

  const renderHead = (
    <>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="h4">{t("LOGINBANNERTITLE")}</Typography>
        <Typography variant="h6" style={{ fontSize: '12px' }}>{t("LOGIN_BANNER_SUB_TITLE")}</Typography>
      </Stack>
    </>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <Typography variant="h4" style={{ fontSize: '15px' }}>{t("Login In")}</Typography>
      <RHFTextField autoComplete='off' name="email" label={t('EMAIL_ADDRESS_LABEL') as any} />

      <RHFTextField autoComplete='off'
        name="password"
        label={t('PASSWORD_LABEL') as any}
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Link variant="subtitle2" href={paths.auth.jwt.clientForgotPassword} component={RouterLink} color="inherit" underline="always" sx={{ alignSelf: 'flex-end' }}>
        {t('FORGOT_PASSWORD_LINK_LABLE')}?
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">{t('NEW_USER_LABEL')}?</Typography>

        <Link component={RouterLink} href={paths.auth.jwt.clientRegister} variant="subtitle2">
          {t('CREATE_ACCOUNT_LABEL')}
        </Link>
      </Stack>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {renderHead}
      {renderForm}
    </FormProvider>
  );
}
