import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: { label: string; value: any }[];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  controlObj:any
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any;
};

export default function DAS360RadioGroup({onChangeHandler,currentData,conditionFlags,controlObj, 
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  ...other
}: Props) {
  const { control,register,setValue,getValues  } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;

  const labelledby = label ? `${name}-${label}` : '';

  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if(controlObj.required && conditionFlags?.show==="flex" && !controlObj.showOnlyInGrid)
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })

    const setLogData = useCallback((field:any,newValue:any)=>{
      if(newValue!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
        oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
      
        if(onChangeHandler) {
          const onchngeDaa ={oldValue,newValue}
          onChangeHandler(onchngeDaa,controlObj);
        }
        console.log(controlObj)
        
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue,newValue})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])
  return (
    <Controller  
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>{!controlObj.showOnlyInGrid &&   <FormControl component="fieldset"  disabled={controlObj.isReadonly || !conditionFlags?.enable}>
       {label && (
            <Typography variant='body2' style={{paddingTop:'7px'}}>
              {label}
            </Typography>
          )}

          <RadioGroup   {...field} {...register } aria-labelledby={labelledby} row={row} {...other}   onChange={(e)=> {
            setValue(name,e.target.value);
            setLogData(field,e.target.value);            
          }} >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  '&:not(:last-of-type)': {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing || 2,
                    },
                  }),
                }}
              />
            ))}
          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      }</> 
      )}
    />
  );
}
