import { Autocomplete, Chip, Divider, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { ColorPicker } from 'material-ui-color';
import { alpha } from '@mui/material/styles';
import moment from 'moment';
import { Fragment, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDateRangePicker } from 'src/components/custom-date-range-picker';
import Editor from 'src/components/editor';
import { RHFAutocomplete, RHFCheckbox, RHFEditor, RHFRadioGroup, RHFSelect, RHFSwitch, RHFTextField, RHFUpload, RHFUploadAvatar } from 'src/components/hook-form';
import DAS360Autocomplete from 'src/components/hook-form/das360-autocomplete';
import { DAS360Checkbox } from 'src/components/hook-form/das360-checkbox';
import DAS360Editor from 'src/components/hook-form/das360-editor';
import DAS360MultiSelectAutocomplete from 'src/components/hook-form/das360-multiselect-autocomplete';
import DAS360RadioGroup from 'src/components/hook-form/das360-radio-group';
import { DAS360Select } from 'src/components/hook-form/das360-select';
import DAS360Switch from 'src/components/hook-form/das360-switch';
import DAS360DatePicker from 'src/components/hook-form/dsa360-date-picker';
import DAS360TextField from 'src/components/hook-form/dsa360-text-field';
import Iconify from 'src/components/iconify';
import { CustomFile, Upload, UploadBox } from "src/components/upload";
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { conditionFlag } from 'src/utils/dynamic.form.generation.common.code';
import { fData } from 'src/utils/format-number';
import DAS360TimePicker from 'src/components/hook-form/dsa360-time-picker';
import { border, borderColor } from '@mui/system';

type Props = {
    formDetails: any;
    currentData: any;
    indexValue: any;
    setValue: any;
    getValues: any;
    control: any;
    subFormName?: any;
    inputTypeSize?: any;
    onAttachmentClick?: any;
    onNotesClick?: any;
    onSubmitQueryClick?: any;
    onChangeHandler?: any;
};

export default function DynamicFormTemplate({ formDetails, setValue, getValues, control, currentData, indexValue, subFormName = "", inputTypeSize, onAttachmentClick, onNotesClick, onSubmitQueryClick, onChangeHandler }: Props) {
    const { t } = useLocales();
    const rangeInputPicker = useDateRangePicker(new Date(), new Date());
    // const [value, setValue] = useState<Date | null>(new Date());
    const handleDropSingleFile = useCallback((acceptedFiles: File[], column: any) => {
        const file = acceptedFiles[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            setValue(column, fileReader.result, { shouldValidate: true });
        }
        fileReader.onerror = (error) => {
            console.log(error);
        }
    }, [setValue])

    // const bgColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#e1f4ff',padding:'10px',borderRadius:'10px'}:{};
    const bgColor:any = {}

    const renderIcons = useCallback((controlObj: any) =>
        <Grid item xs={12} lg={3} sx={{ float: 'right', textAlign: "right" }}>
            {controlObj && controlObj.attachment && <Tooltip key={`${crypto.randomUUID()}`} title="Quick Attachment" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => { onAttachmentClick(controlObj) }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="ic:baseline-attachment" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.notes && <Tooltip key={`${crypto.randomUUID()}`} title="Quick Note" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => { onNotesClick(controlObj) }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="hugeicons:note-edit" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.query && <Tooltip key={`${crypto.randomUUID()}`} title="Submit Query" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => {
                    const value = getValues(`${subFormName}${controlObj.columnName}`)
                    console.log(value);
                    controlObj.itemValue = controlObj.inputType === 'DATETIME' ? moment(value).format(controlObj.format || 'dd/MMM/YYYY') : value;
                    onSubmitQueryClick(controlObj);
                }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="ph:question-fill" />
                </IconButton>
            </Tooltip>
            }
            {controlObj && controlObj.clear && <Tooltip key={`${crypto.randomUUID()}`} title="Clear All" placement="top" arrow>
                <IconButton key={`${crypto.randomUUID()}`} onClick={() => {
                    setValue(`${subFormName}${controlObj.columnName}`, '');

                }}>
                    <Iconify key={`${crypto.randomUUID()}`} icon="f7:clear-fill" />
                </IconButton>
            </Tooltip>
            }
        </Grid>
        , [])

    const lgWidth = (controlObj: any) => controlObj.attachment || controlObj.notes || controlObj.query || controlObj.clear ? 9 : 12

    const renderForm = useCallback((formDetail: any, index: any) => {
        const newRecords = formDetail && formDetail.map((controlObj: any, controlIndex: any) => {
            const randomUUID: any = crypto.randomUUID();
            let conditionFlags = { show: "flex", enable: true };
            // controlObj.menuId=formDetail.menuId;
            if (controlObj.conditionalLogic !=='') {
                conditionFlags = conditionFlag(subFormName, controlObj, getValues);
            }

            controlObj.subFormName = subFormName;

            switch (controlObj.inputType) {
                // case "TEXTAREA":
                //     return <DAS360Editor onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj}
                //          key={`${randomUUID}6`} size={inputTypeSize}
                //         name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />

                case "DATETIME": {
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container sx={{...bgColor}} style={{ "display": conditionFlags?.show }}>
                    <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}>
                        <DAS360DatePicker onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj}
                             key={`${randomUUID}6`} size={inputTypeSize}
                            name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                    </Grid>
                    {renderIcons(controlObj)}
                </Grid>}
                    </>
                }
                case "PASSWORD":
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}} style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}
                            display={{ xs: 'none', lg: 'flex' }}>
                            <DAS360TextField onChangeHandler={onChangeHandler} key={`${crypto.randomUUID()}`} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj}  autoComplete='off' type='password' id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>

                case "NUMBER":
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}} style={{ "display": conditionFlags?.show }} >
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)} display={{ xs: 'none', lg: 'flex' }}>
                            <DAS360TextField onChangeHandler={onChangeHandler} key={`${crypto.randomUUID()}`} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj}  autoComplete='off' type='number' id={controlObj.columnName} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                            {/* <RHFTextField  autoComplete='off' type='number' id={controlObj.columnName} key={`${randomUUID}7`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} /> */}
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>

                case "EMAILADDRESS":
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}} style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)} >
                            <DAS360TextField onChangeHandler={onChangeHandler} key={`${crypto.randomUUID()}`} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj}  type='email' autoComplete='off' id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                case "TEXTBOX": {
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}}  style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}  >
                            <DAS360TextField onChangeHandler={onChangeHandler} key={`${crypto.randomUUID()}`} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} autoComplete='off'  id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                }
                case "UPLOADFILE": {
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}} style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}  >
                            <RHFTextField type='file' autoComplete='off'  defaultValue=""
                                key={`${randomUUID}8`}
                                id={`${subFormName}${controlObj.columnName}`}
                                size={inputTypeSize} name={`${randomUUID}8`} label={t(controlObj.label)}
                                onChange={(event: any) => {
                                    const file = event.target.files[0];
                                    const reader = new FileReader();
                                    reader.onload = (e: any) => {
                                        setValue(`${subFormName}${controlObj.columnName}`, e.target.result);
                                    };

                                    if (file.size > 10e6) {
                                        window.alert("Please upload a file smaller than 10 MB");
                                        return false;
                                    }
                                    reader.readAsDataURL(event.target.files[0]);
                                    return true
                                }
                                } />
                            {/* <UploadBox
                                placeholder={
                                    <Stack spacing={0.5} alignItems="center">
                                        <Iconify icon="eva:cloud-upload-fill" width={40} />
                                        <Typography variant="body2">Upload file</Typography>
                                    </Stack>
                                }
                                sx={{ flexGrow: 1, height: 'auto', py: 2.5, mb: 3 }}
                            /> */}
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                }

                case "DROPDOWN": {
                    let optionData: any = !controlObj.dependencyDropdownKeyCol && !controlObj.parentColumnName ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol?controlObj.dependencyDropdownKeyCol:controlObj.parentColumnName], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData = typeof optionData === 'string' ? [] : optionData
                    // return <RHFSelect key={`${randomUUID}9`} id={`${subFormName}${controlObj.columnName}`} 
                    // name={`${subFormName}${controlObj.columnName}`}
                    //     label={t(controlObj.label)} size={inputTypeSize}
                    //     onChange={(e) => {
                    //         const filterData = optionData?.filter((x: any) => x.Id === e.target.value)[0]
                    //         setValue(`${subFormName}${controlObj.columnName}1`, filterData[controlObj.dropdownLabelName]);
                    //         setValue(`${subFormName}${controlObj.columnName}`, e.target.value);
                    //         if (controlObj.childReference) {
                    //             controlObj.childReference.split(',').forEach((item: any) => {
                    //                 const dataD = (controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)).filter((x: any) => x.Id === e.target.value)[0][item];
                    //                 setValue(controlObj.refDependencyDropdownKeyCol, dataD)
                    //             })
                    //         }
                    //     }}
                    // >
                    //     <MenuItem key={`${randomUUID}10`} value=''>None</MenuItem>
                    //     <Divider key={`${randomUUID}11`} sx={{ borderStyle: 'dashed' }} />
                    //     {optionData?.map((item: any) => (
                    //         <MenuItem key={`${randomUUID}${item.columnName}${item.Id}`} value={item.Id}> {item[controlObj.dropdownLabelName]} </MenuItem>
                    //     ))}
                    // </RHFSelect>
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}} style={{ "display": conditionFlags?.show }}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}  >
                            <DAS360Select onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} key={`${crypto.randomUUID()}`} id={`${subFormName}${controlObj.columnName}`} name={`${subFormName}${controlObj.columnName}`}
                                label={t(controlObj.label)} size={inputTypeSize}>
                                <MenuItem key={`${crypto.randomUUID()}`} value=''>None</MenuItem>
                                <Divider key={`${crypto.randomUUID()}`} sx={{ borderStyle: 'dashed' }} />
                                {optionData?.map((item: any) => (
                                    <MenuItem key={`${randomUUID}${item.columnName}${item.Id}`} value={item.Id}> {item[controlObj.dropdownLabelName]} </MenuItem>
                                ))}
                            </DAS360Select>
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                }

                case "MULTISELECT": {
                    let optionData: any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData = typeof optionData === 'string' ? [] : optionData
                    let defaultControlValuesList: any = [];
                    defaultControlValuesList = optionData.filter((x: any) => getValues()[`${subFormName}${controlObj.columnName}`]?.split(',').includes(x.Id.toString()))
                    return <DAS360MultiSelectAutocomplete onChangeHandler={onChangeHandler} options={optionData}
                        defaultValue={defaultControlValuesList} currentData={currentData} conditionFlags={conditionFlags}
                        controlObj={controlObj} key={`${crypto.randomUUID()}`}
                        name={`${subFormName}${controlObj.columnName}`}
                        label={controlObj.label}
                    />
                }
                case "COLORPICKER": {
                    return <Stack spacing={1} sx={{ p: 1 }} key={`${crypto.randomUUID()}`} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(2, 1fr)` }}> {controlObj.label ? controlObj.label : 'Pick Color'}:
                        <ColorPicker disableAlpha key={`dynamic${controlObj.columnName}`} defaultValue={currentData[`${subFormName}${controlObj.columnName}`]} value={getValues()[`${subFormName}${controlObj.columnName}`]}
                            onChange={(data: any) => {
                                setValue(`${subFormName}${controlObj.columnName}`, data.css.backgroundColor);
                            }} />
                    </Stack>
                }
                case "PICTUREUPLOAD": {
                    return <RHFUploadAvatar key={`${crypto.randomUUID()}`} minSize={20}
                        helperText={
                            <Typography key={`${crypto.randomUUID()}`}
                                variant="caption"
                                sx={{
                                    mt: 3,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.disabled',
                                }}
                            >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br /> max size of {fData(3145728)}
                            </Typography>
                        }
                        name={`${subFormName}${controlObj.columnName}`}
                        onDrop={(e: any) => handleDropSingleFile(e, `${subFormName}${controlObj.columnName}`)}
                        onDelete={() => setValue(`${subFormName}${controlObj.columnName}`, '', { shouldValidate: true })}
                    />
                }
                case "FILEUPLOAD": {
                    return <Upload onDrop={(e: any) => handleDropSingleFile(e, `${subFormName}${controlObj.columnName}`)} key={`${crypto.randomUUID()}`} onDelete={() => setValue(`${subFormName}${controlObj.columnName}`, '', { shouldValidate: true })} />
                }
                case "TOGGLE": {
                    return <DAS360Switch onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} id={`${subFormName}${controlObj.columnName}`}
                        key={`${crypto.randomUUID()}`} name={`${subFormName}${controlObj.columnName}`}
                        label={t(controlObj.checkboxLabel)} />
                }
                case "CHECKBOX": {
                    return <DAS360Checkbox onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} id={`${subFormName}${controlObj.columnName}`} key={`${crypto.randomUUID()}`} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.checkboxLabel)} />
                }
                case "RADIOBUTTON": {
                    const radioOption = controlObj?.options?.split(',')?.map((option: any, indexD: any) => ({ label: option, value: option }))
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container sx={{...bgColor}} >
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={6} display={{ xs: 'none', lg: 'flex',fontsize:'0.875rem' }}>
                            <Typography variant='body2' textAlign={controlObj.alignment} sx={{paddingTop:'5px'}}>{controlObj.label}</Typography>
                        </Grid>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={3} display={{ xs: 'none', lg: 'flex' }}>
                            <DAS360RadioGroup onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} row name={`${subFormName}${controlObj.columnName}`} options={[...radioOption]} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                }
                case "CUSTOMRADIOBUTTON": {
                    const radioOption = controlObj?.options?.split(',')?.map((option: any, indexD: any) => ({ label: option, value: option }))
                    return <>{conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container sx={{...bgColor}} >
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={3} display={{ xs: 'none', lg: 'flex',fontsize:'0.875rem' }}>
                            {t(controlObj.label)}
                        </Grid>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={6} display={{ xs: 'none', lg: 'flex' }}>
                            <DAS360RadioGroup onChangeHandler={onChangeHandler}  currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} row name={`${subFormName}${controlObj.columnName}`} options={[...radioOption]} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}</>
                }
                case "AUTOCOMPLETE": {
                    const autoComplete = controlObj?.options?.split(',') || [];
                    return <DAS360Autocomplete sx={{...bgColor}} size={inputTypeSize} onChangeHandler={onChangeHandler} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} key={`${crypto.randomUUID()}`}
                        name={`${subFormName}${controlObj.columnName}`}
                        label={controlObj.label}
                        options={autoComplete}
                        getOptionLabel={(option: any) => (option)}
                        isOptionEqualToValue={(option: any, value: any) => option === value}
                        renderOption={(props: any, option: any) => (
                            <li key={`${crypto.randomUUID()}`} {...props}>
                                {option}
                            </li>
                        )}
                    />
                }
                case "H1": {
                    return <Fragment key={`${randomUUID}2`} >
                        <Typography variant='subtitle2' textAlign={controlObj.alignment} style={{ width: "100%", paddingTop: "10px" }}>{controlObj.label}</Typography>
                        {/* <Typography variant="subtitle1" key={`${randomUUID}2`} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography> */}
                        <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                    </Fragment>
                }
                case "H2": {
                    return <Fragment key={`${randomUUID}2`} >
                        <Typography variant='subtitle2' textAlign={controlObj.alignment} style={{ width: "100%", paddingTop: "10px" }}>{controlObj.label}</Typography>
                        {/* <Typography variant="subtitle1" key={`${randomUUID}2`} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography> */}
                        <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                    </Fragment>
                    //  <Typography variant='h5' textAlign={controlObj.alignment}>{controlObj.label}</Typography>
                }
                case "H3": {
                    return <>
                    <Stack  sx={{ p: 1, my: 0, bgcolor: (theme) => alpha('#418bca', 1),color:'#ffffff' }} key={Math.random()} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                        <Typography variant='h6' textAlign={controlObj.alignment}>{controlObj.label?.split(",")[0]}</Typography>
                        {controlObj.label?.split(",")?.map((item: any, i: any) => (
                            <>{i !== 0 && <Typography variant='subtitle2' textAlign={controlObj.alignment}>{item}</Typography>}</>
                        )
                        )}
                    </Stack>
                    <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                    </>
                }
                case "H4": {
                    return <>
                    <Stack  sx={{ p: 1, my: 0, bgcolor: (theme) => alpha('#418bca', 1),color:'#ffffff' }} key={Math.random()} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                        <Typography variant='h6' textAlign={controlObj.alignment}>{controlObj.label?.split(",")[0]}</Typography>
                        {controlObj.label?.split(",")?.map((item: any, i: any) => (
                            <>{i !== 0 && <Typography variant='subtitle2' textAlign={controlObj.alignment}>{item}</Typography>}</>
                        )
                        )}
                    </Stack>
                    <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                    </>
                }
                case "H5": {
                    return  <>
                    <Stack  sx={{ p: 1, my: 0, bgcolor: (theme) => alpha('#418bca', 1),color:'#ffffff' }} key={Math.random()} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                        <Typography variant='h6' textAlign={controlObj.alignment}>{controlObj.label?.split(",")[0]}</Typography>
                        {controlObj.label?.split(",")?.map((item: any, i: any) => (
                            <>{i !== 0 && <Typography variant='subtitle2' textAlign={controlObj.alignment}>{item}</Typography>}</>
                        )
                        )}
                    </Stack>
                    <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                    </>
                }
                case "H7": {
                    return <Typography variant="subtitle2" style={{ fontSize: '12px' }} textAlign={controlObj.alignment}>{controlObj.label}</Typography>
                }
                case "NOTE": {
                    return<div dangerouslySetInnerHTML={{__html:controlObj.label}}/>
                }
                case "TIMEPICKER": {
                    return <>
                    {conditionFlags?.show === 'flex' && <Grid key={`${crypto.randomUUID()}`} container  sx={{...bgColor}}>
                        <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={lgWidth(controlObj)}  >
                        <DAS360TimePicker subFormName={subFormName} onChangeHandler={onChangeHandler} key={`${crypto.randomUUID()}`} currentData={currentData} conditionFlags={conditionFlags} controlObj={controlObj} autoComplete='off'  id={`${subFormName}${controlObj.columnName}`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                        </Grid>
                        {renderIcons(controlObj)}
                    </Grid>}
                    </> 
                }
                default: return "";
            }
        })
        return newRecords;
    }, [getValues])

    return (
        <Fragment key={Math.random()}>
            {renderForm(formDetails, indexValue)}
        </Fragment>
    )
}