import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IColumnDetailState } from 'src/types/column.details'; 
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IColumnDetailState = {
    columnDetails:[],
    columnDetailStatus:{
        loading:false,
        empty:false,
        error:null
    },
    columnDetailDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'columnDetail',
    initialState,
    reducers:{
        getColumnDetailStart(state) {
            state.columnDetailStatus.loading=true;
            state.columnDetailStatus.empty=false;
            state.columnDetailStatus.error = null;
        },
        getColumnDetailFailure(state, action) {
            state.columnDetailStatus.loading=true;
            state.columnDetailStatus.empty=false;
            state.columnDetailStatus.error = action.payload;
        },
        getColumnDetailSuccess(state, action) {
            const columnDetail = action.payload;
            state.columnDetails = columnDetail;
            state.columnDetailStatus.loading = false;
            state.columnDetailStatus.empty = !columnDetail.length;
            state.columnDetailStatus.error = null;
        },
        deletecolumnDetailStart(state) {
            state.columnDetailDelete.loading=true;
            state.columnDetailDelete.empty=false;
            state.columnDetailDelete.error = null;
        },
        deletecolumnDetailFailure(state, action) {
            state.columnDetailDelete.loading=true;
            state.columnDetailDelete.empty=false;
            state.columnDetailDelete.error = action.payload;
        },
        deletecolumnDetailSuccess(state, action) {
            const columnDetailDelete = action.payload;
            state.columnDetailDelete = columnDetailDelete;
            state.columnDetailDelete.loading = false;
            state.columnDetailDelete.empty = action.payload;
            state.columnDetailDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getColumnDetailStart,
    getColumnDetailFailure,
    getColumnDetailSuccess,
    deletecolumnDetailStart,
    deletecolumnDetailFailure,
    deletecolumnDetailSuccess
} = slice.actions;
 

export function getColumnDetails(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getColumnDetailStart());
        try {
            await axios.get(`${API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.GETTABLECOLUMNS}/${data.id}`).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getColumnDetailSuccess(res.data.data));
            else 
                 dispatch(slice.actions.deletecolumnDetailFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.deletecolumnDetailFailure(error));
        }
    }
}

export function deleteColumnDetail(id: any) {
return  axios.delete(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.DELETE}/${id}`);
}

export function deleteTable(data: any) {
    return  axios.post(`${API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.DELETE}`,data);
    }
    

export function getTableInfo() {
    return axios.get(API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.GETTABLEINFO);
}

export function getFormDetailByTableId(id:any) {
    return axios.get(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GETFORMDETAILSBYTABLEID}/${id}`);
} 
export function getFormDetailByPageId(id:any) {
    return axios.get(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GETFORMDETAILSBYPAGEID}/${id}`);
} 

export function getViewDataById(data:any) {
    return axios.post(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GETVIEWDATABYID}`,data);
}

export function getFormViewDataById(data:any) {
    return axios.post(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GEFORMTVIEWDATABYID}`,data);
}

export function getViewDataByTId(data:any) {
    return axios.post(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GETVIEWDATABYTID}`,data);
}

export function getDetailById(id: any,pageId:any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMDETAILS.GETDETAILBYID}/${id}/${pageId}`);
}

export function addUpdateColumnDetail(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.INSERTUPDATETABLECONFIGURATION,data);
}

export function addUpdateTable(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.INSERTUPDATETABLE,data);
}

export function insertUpdateFormDetail(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.DYNAMICFORM.ADDUPDATEDYNAMICFORM,data);
}

export function deleteFormDataDetail(id: any, pageId:any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.DYNAMICFORM.DELETEFORMDATADETAIL}/${id}/${pageId}`);
}

export function deleteTableColumn(id: any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.TABLECONFIGURATION.DELETETABLECOLUMN}/${id}`);
}